<template>
    <v-app>
        <v-row>
            <v-col cols="12">
                <div class="card card-custom ">
                    <div class="card-body p-0">
                        <div
                                class="wizard wizard-2"
                                id="kt_wizard_v2"
                                data-wizard-state="step-first"
                                data-wizard-clickable="true"
                        >
                            <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                                <div class="row">
                                    <div class="col-6">
                                        <h3>
                                            Assignment Detail
                                        </h3>
                                        <router-link :to="{name:'dashboard'}">
                                            Dashboard
                                        </router-link>
                                        \
                                        <router-link :to="{name:'assignment'}">
                                            Assignment
                                        </router-link>
                                        \
                                        Assignment Detail
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </v-col>
            <v-col cols="12">
                <v-card flat class="mb-3">
                    <v-card-title>
                        <div class="d-flex flex flex-column flex-md-row justify-content-between mr-3">
                            <a
                                    href="#"
                                    v-if="assignment"
                                    class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                            >{{assignment.title}}</a
                            >
                            <div class="my-lg-0 my-3">
                                <strong v-if="assignment.total_marks">Total Marks </strong> <span class="badge badge-primary rounded-full"> {{assignment.total_marks}}</span>
                                <strong class="ml-2" v-if="assignment.pass_marks">Pass Marks </strong>
                                  <span class="badge badge-primary rounded-full">  {{assignment.pass_marks}} </span>
                            </div>
                        </div>
                    </v-card-title>
                    <v-card-text>

                        <v-card-subtitle>
                            <span v-if="assignment.course"> Course : <strong>{{ assignment.course.title || 'N/A' }} |  </strong></span>
                            <span v-if="assignment.course"> Program : <strong>{{ assignment.course.program.title|| 'N/A' }} |  </strong></span>
                            <span v-if="assignment.course"> Grade : <strong>{{ assignment.course.grade.title|| 'N/A' }} |  </strong></span>
                            <span v-if="assignment.submission_type"> Submission Type : <strong class="text-uppercase">{{ assignment.submission_type | format_status | capitalize   }}   </strong></span>
                            <br>
                            <span class="d-block my-md-0 my-1" v-if="assignment.enable_submission_date"> Start Date : <strong>{{ assignment.allow_submission_date | moment("dddd, MMMM Do YYYY, hh:mm a") ||  'N/A' }}   </strong></span>

                            <span class=" d-block mb-md-0 mb-1" v-if="assignment.enable_due_date "> Due Date : <strong>{{ assignment.due_date | moment("dddd, MMMM Do YYYY, hh:mm a") ||  'N/A' }}   </strong></span>

                        </v-card-subtitle>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
              <v-alert
                  v-if="assignment!=null && !assignment.can_submit"
                  dense
                  border="left"
                  type="error"
              >
                Assignment Submission is now closed
                <span>{{ assignment.last_submission | moment("calendar")}}</span>
              </v-alert>
              <template>
                <div>
                  <v-expansion-panels
                      v-model="panel"
                      multiple
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header><h3>Question/Details</h3></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p v-html="assignment.description" class="font-weight-bold text-dark-50">
                        </p>

                        <assignment-attachment :assignment_id="assignment_id"></assignment-attachment>
                          <v-btn class="float-right btn btn-primary text-white mt-5" v-if="!submission || assignment.can_resubmit
                                           && submission.status!='graded'" :disabled="checkCutOffDate"
                                 @click.prevent="submit(assignment_id)">
                              {{submission? 'Resubmit' : 'Submit'}} Assignment
                          </v-btn>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header><h3>Submission Summary</h3></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div v-if="submission" class="col-12">
                          <v-chip v-if="submission"
                                  class="ma-2 text-capitalize"
                                  color="green"
                                  text-color="white"
                                  small
                          >
                            {{submission.status || 'Pending'}}
                          </v-chip>
                          <span v-if="submission && submission.submitted_date">  <strong>{{submission.submitted_at}}</strong></span>
                          <br>
                          <v-divider></v-divider>
                          <div class="mt-10">

                            <div v-if="submission.assignment_text">
                              <h4>Answer Submitted</h4>
                              <div class="bg-light p-5" v-html="submission.assignment_text">

                              </div>

                            </div>
                            <span v-if="submission.attachment">
                                               <h4>Assignment Attachments</h4>
                                                <v-row class="row media-gallery  mt-5" v-if="mediaLoading">
                                                    <v-col cols="12">
                                                      <v-skeleton-loader
                                                          class="mx-auto"
                                                          type="list-item-two-line"
                                                      ></v-skeleton-loader>
                                                    </v-col>
                                                </v-row>

                                            <div class="row media-gallery  mt-5"
                                                 v-if="submission.attachment.length">
                                                <div class="col-md-12 m-1"
                                                     v-for="(image, index) in submission.attachment" :key="index">
                                                    <a :href="image.path" target="_blank" class="mr-3">
                                                        <img src="/media/svg/files/copy.png" style="height: 24px; text-align: left"
                                                             :alt="`Image Uploader ${index}`"/>  <span class="name ml-2">{{ image.title }}</span>
                                                    </a>
                                                </div>
                                            </div>
                                            </span>
                          </div>


                        </div>
                      </v-expansion-panel-content>

                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header><h3>Grading Summary</h3></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-chip v-if="marking"
                                class="ma-2 text-capitalize"
                                :color="marking.result=='pass' ? 'green' : 'red'"
                                text-color="white"
                        >
                          {{marking.result=='pass' ? 'Pass' : 'Fail' || 'Pending'}}
                        </v-chip>
                        <span v-if="marking && marking.mark_obtained">Mark Obtained<strong> {{`${marking.mark_obtained}/${assignment.total_marks}`}} </strong></span>
                        <span class="float-right" v-if="submission && submission.graded_date">Graded On <strong>{{submission.graded_date_formated}}</strong></span>
                        <div v-if="marking">
                          <v-divider></v-divider>
                          <div class="d-flex">
                            <v-avatar
                                v-if="submission.graded_by"
                                color="primary"
                                class="text-white"
                                size="56"
                            >
                              {{submission.graded_by_user.first_name}}
                            </v-avatar>
                            <div class="d-flex flex-column mt-3 ml-2">
                              <p class="" v-if="marking.feed_back">FeedBack </p>
                              <p class="" v-if="marking.feed_back" v-html="marking.feed_back"></p>
                            </div>

                          </div>
                        </div>

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </template>

            </v-col>
        </v-row>
    </v-app>
</template>

<script>
    import {AssignmentMixin} from "@/mixins/AssignmentMixin";
    import AssignmentSubmissionService from "@/core/services/assignement/AssignmentSubmissionService";
    import AssignmentMarkingService from "@/core/services/assignement/AssignmentMarkingService";
    import AssignmentAttachment from "./AssignmentAttachment";
    import AssignmentAttachmentService from "@/core/services/assignement/AssignmentAttachmentService";

    const assignmentSubmissionService = new AssignmentSubmissionService();

    const assignmentMarkingService = new AssignmentMarkingService();
    const assignmentAttachmentService = new AssignmentAttachmentService();
    export default {
        name: "AssignmentDetail",
        mixins: [AssignmentMixin],
        components: {AssignmentAttachment},
        data() {
            return {
                medias: [],
                submission: null,
                marking: null,
                mediaLoading: false,
                panel: [0],
            }
        },
        computed: {
            assignment_id() {
                return this.$route.params.assignment_id;
            },
            currentUser() {
                return this.$store.getters.currentUser;
            },
            today() {
                return this.$moment(new Date()).format("dd-mm-yyyy");
            }
        },
        mounted() {
            this.getSingleAssignment(this.assignment_id);
            this.getByAssignmentSubmission();
            this.getByAssignmentMarking();
        },
        methods: {
            __get() {
                this.getSingleAssignment();

            },
            openToggle(){
              if(this.marking){
                this.panel = [];
                this.panel.push(2);
              }
            },
            submit(id) {
                this.$router.push({
                    name: 'assignment-submit', params: {
                        assignment_id: id
                    }
                })
            },
            getByAssignmentSubmission() {
                assignmentSubmissionService.getByAssignmentAndStudentId(this.assignment_id).then(response => {
                    this.submission = response.data.submission;
                    if (this.submission.submission_type === 'file_submission') {
                        this.getMedia();
                    }
                });
            },
            getByAssignmentMarking() {
                assignmentMarkingService.getByAssignmentAndStudentId(this.assignment_id).then(response => {
                    this.marking = response.data.marking;
                    this.marking.assignments_submission_id = this.submission.id;
                  this.openToggle();
                });
            },
            getMedia(index = null) {
                let search = {
                    type: "submission",
                    student_id: this.currentUser.id,
                }
                this.mediaLoading = true;
                assignmentAttachmentService
                    .paginate(this.assignment_id, search)
                    .then(response => {
                        this.medias = response.data.data;
                    })
                    .catch((err) => {
                        // console.log(err)
                    }).finally(() => {
                    this.mediaLoading = false;
                });
            },
            submitAssignment() {
                this.$refs["submitAssignment"].showModal();
            }
        },
    }
</script>

<style scoped>

</style>
